import { endOfDay, isValid, parse, parseISO, startOfDay, sub } from "date-fns";
import { format, toDate, toZonedTime } from "date-fns-tz";

// Standard date-fns formats to try when parsing
const dateFormats = [
  "yyyy-MM-dd",
  "MM/dd/yyyy",
  "dd/MM/yyyy",
  "MM-dd-yyyy",
  "dd-MM-yyyy",
  "yyyy/MM/dd",
  "yyyy.MM.dd",
  "dd.MM.yyyy",
  "MM.dd.yyyy",
  "MMMM do, yyyy",
  "MMMM d, yyyy",
  "MMM d, yyyy",
  "MMM do, yyyy",
  "MMMM dd, yyyy",
  "MM/dd/yy",
  "dd/MM/yy",
  "MM-dd-yy",
  "dd-MM-yy",
  "yyyy-M-d",
  "d-M-yyyy",
  "d/MM/yyyy",
  "MM/d/yyyy",
  "do MMMM yyyy",
  "dd MMM yyyy",
  "MMM d, yyyy",
  "MMM dd, yyyy",
  "PPPP",
  "PPPPpppp",
  "PPP",
  "PPp",
  "PP",
  "P",
  "p",
  "MMM dd, yyyy h:mm a",
  "MMMM dd, yyyy h:mm a",
  "MMM dd, yyyy h:mm aaaa",
  "MMMM dd, yyyy h:mm aaaa",
];

// Moment.js to date-fns format mapping
const momentToDateFns = {
  'LT': 'h:mm a',
  'LTS': 'h:mm:ss a',
  'L': 'MM/dd/yyyy',
  'l': 'M/d/yyyy',
  'll': 'MMM d, yyyy',
  'LL': 'MMMM d, yyyy',
  'lll': 'MMM d, yyyy h:mm a',
  'LLL': 'MMMM d, yyyy h:mm a',
  'llll': 'ddd, MMM d, yyyy h:mm a',
  'LLLL': 'dddd, MMMM d, yyyy h:mm a',
};


class LocalTimeConverter {
  constructor(date = new Date(), isUTC = false) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.date = this.parseDate(date, isUTC);
    if (!isValid(this.date)) {
      throw new Error(`Invalid date: ${date}`);
    }
  }

  static utc(date = new Date()) {
    return new LocalTimeConverter(date, true);
  }

  parseDate(date, isUTC) {
    // Handle Date objects
    if (date instanceof Date) {
      return isUTC ? toDate(date) : toZonedTime(date, this.timeZone);
    }

    // Handle string dates
    if (typeof date === 'string') {
      // First try parsing as ISO format (YYYY-MM-DD)
      if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        const [year, month, day] = date.split('-').map(Number);
        const parsedDate = new Date(year, month - 1, day);
        return isUTC ? toDate(parsedDate) : toZonedTime(parsedDate, this.timeZone);
      }

      // Try parsing as ISO datetime
      let parsedDate = parseISO(date);
      if (isValid(parsedDate)) {
        return isUTC ? toDate(parsedDate) : toZonedTime(parsedDate, this.timeZone);
      }

      // Try standard date-fns formats
      for (let formatString of dateFormats) {
        parsedDate = parse(date, formatString, new Date());
        if (isValid(parsedDate)) {
          return isUTC ? toDate(parsedDate) : toZonedTime(parsedDate, this.timeZone);
        }
      }
    }

    // If all parsing attempts fail, throw error
    throw new Error(`Unable to parse date: ${date}`);
  }

  startOf(unit) {
    if (unit === "day") {
      this.date = startOfDay(this.date);
    }
    return this;
  }

  endOf(unit) {
    if (unit === "day") {
      this.date = endOfDay(this.date);
    }
    return this;
  }

  subtract(amount, unit) {
    this.date = sub(this.date, { [unit]: amount });
    return this;
  }

  format(formatStr = "yyyy-MM-dd") {
    try {
      // Special handling for YYYY-MM-DD format to ensure consistency
      if (formatStr === "yyyy-MM-dd") {
        const year = this.date.getFullYear();
        const month = String(this.date.getMonth() + 1).padStart(2, '0');
        const day = String(this.date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      // Handle moment.js format strings
      let dateFnsFormat = momentToDateFns[formatStr] || formatStr;

      // Handle legacy format strings
      dateFnsFormat = dateFnsFormat
        .replace(/YYYY/g, "yyyy")
        .replace(/DD/g, "dd")
        .replace(/YY/g, "yy");

      return format(this.date, dateFnsFormat, { timeZone: this.timeZone });
    } catch (error) {
      console.error(`Format error for "${formatStr}":`, error);
      // Return original date in ISO format as fallback
      return this.date.toISOString().split('T')[0];
    }
  }
}

const convertToLocalTime = (date = new Date(), dateFormat = null) => {
  // If date is already in YYYY-MM-DD format, don't sanitize
  if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
    const converter = new LocalTimeConverter(date);
    return dateFormat ? converter.format(dateFormat) : converter;
  }
  // For other formats, sanitize first
  // const sanitizedDate = sanitizeDate(date);
  const converter = new LocalTimeConverter(date);
  return dateFormat ? converter.format(dateFormat) : converter;
};

export default convertToLocalTime;
